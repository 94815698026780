import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from "react-bootstrap/Container"
import logo from '../../content/assets/logo.png';
import logotext from '../../content/assets/text_only.png';
import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaTiktok } from 'react-icons/fa';
import { BsArrowUpRight, BsBoxArrowUpRight } from 'react-icons/bs'
import { FiArrowUpRight } from 'react-icons/fi'
import { IconContext } from "react-icons";
import "@fontsource/karla";

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <header className="global-header">
        <div className='mr-2 d-flex justify-content-center navbar-social-icons' style={{borderTop: "0.6em", borderBottom:"0em", borderColor: "rgba(var(--bs-dark-rgb))", borderStyle: "solid", position: "relative", zIndex: 10}}>
          <IconContext.Provider value={{ color: "white" }}>
            <a href="https://www.facebook.com/ProgressivePoliticsNI" target="_blank"><FaFacebookSquare className="socialIcon mx-1" /></a>
            <a href="https://www.instagram.com/progressivepoliticsni" target="_blank"><FaInstagramSquare className="socialIcon mx-1" /></a>
            <a href="https://twitter.com/ProgPoliticsNI" target="_blank"><FaTwitterSquare className="socialIcon mx-1" /></a>
            <a href="https://www.tiktok.com/@progressive_politicsni" target="_blank"><FaTiktok className="socialIcon mx-1" /></a>
          </IconContext.Provider>
        </div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" style={{marginTop:"-2.01em"}}>
          <Container fluid="md" className="navbar-container">
            <Link to="/">
              <div className="d-flex align-items-center mt-1" style={{zIndex: 100}}>
                <img
                  src={logo}
                  height="120em"
                  className="d-inline-block logo"
                  alt="Progressive Politics NI logo"
                />
              </div>
            </Link>
            <img
              src={logotext}
              height="45em"
              className="logo-text"
              alt="Progressive Politics NI logo"
            />
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto dev-nav"></Nav>
              <Nav className="dev-nav">
                <Nav.Link href="/">Home</Nav.Link>
                {/* <Nav.Link href="/articles">Articles</Nav.Link> */}
                <Nav.Link href="/charter">Charter</Nav.Link>
                <Nav.Link href="/get-involved">Get Involved</Nav.Link>
                <Nav.Link href="https://www.facebook.com/groups/428863778929179" target="_blank">Events</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <main>
        {children}
      </main>

      <footer>
        © {new Date().getFullYear()} - Progressive Politics NI
      </footer>
    </div>
  )
}

export default Layout
